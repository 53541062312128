import { render, staticRenderFns } from "./history-detail.vue?vue&type=template&id=201f6d2f&scoped=true&"
import script from "./history-detail.vue?vue&type=script&lang=js&"
export * from "./history-detail.vue?vue&type=script&lang=js&"
import style0 from "./history-detail.vue?vue&type=style&index=0&id=201f6d2f&lang=scss&scoped=true&"
import style1 from "./history-detail.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "201f6d2f",
  null
  
)

export default component.exports