<template>
  <div class="passenger-transporation">
    <app-sidebar :open="openSidebar" @toggle-sidebar="openSidebar = !openSidebar">
      <template slot="header">История движения ТС - {{ number }}
        <h5 v-if="transporter">Организация: <span>{{ transporter.name }}</span></h5>
        <div class="h5-date">
          <date-picker
            :clearable="false"
            type="date"
            v-model="filter.date"
            lang="ru"
            :first-day-of-week="1"
            value-type="format"
            :disabled-date="disabledBeforeAfter"
            format="YYYY-MM-DD"
            :editable="false"
            @change="changeDates()"
          ></date-picker>
          <button class="btn btn-primary" style="width: 25%;" @click.prevent="goBack()">Назад</button>
        </div>
      </template>
      <template>
        <div class="preloader" v-if="loading">
          <img src="../../../../src/assets/loading.svg">
        </div>
        <div class="history" v-else>
          <ul class="date-list">
            <li v-for="(dateItem, index) in historyList" :key="index">
              <span>{{ index }}</span>
              <ul class="list">
                <li class="list__item" @click.prevent="showRoute(item)" v-for="(item, index) in dateItem" :key="index">
                  <p :class="{ active: detailLayer.id === item.id }">
                    {{ item.date_from}} - {{ item.date_to }}
                    <span v-if="item.type === 'trip'">Поездка</span>
                    <span v-else-if="item.type === 'trip_on_road'">Движение по дороге - {{ item.road.name }} ({{ Number(item.distance).toFixed(1) }} км)
                    </span>
                    <span v-else>Стоянка</span>
                    ({{ item.diffTime }})
                  </p>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </template>
    </app-sidebar>
    <div
      id="map"
      class="history-map"
    ></div>
    <div class="play-controls" v-if="detailLayer.show">
      <div class="text-center">
        <button class="btn btn-warning" v-if="isPlayed" @click="markerMove(false)">Пауза</button>
        <button class="btn btn-primary" v-else @click="markerMove(true)">Воспроизвести</button>
      </div>

      <div class="speed-block">
        <p>Скорость: {{ Math.pow(2, this.speed) }}x</p>
        <input type="range" v-model="speed" min="0" max="8" class="range">
      </div>

      <div class="time-block" v-if="selectedRoute">
        <p>Время: {{ timeFormatted }}</p>
        <input
          type="range"
          class="range"
          step="15"
          v-model="innerTime"
          @input="updateTime"
          @mousedown="startDragging"
          @mouseup="endDragging"
          :min="selectedRouteFrom"
          :max="selectedRouteTo"
        >
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import AppSidebar from '@/components/app/app-sidebar';
import { MapService } from '@/services/map-service';
import * as moment from 'moment';
import L from 'leaflet';
import {} from 'leaflet-polylinedecorator';
import '@/services/layers/moving-marker';
import { LayersService } from '@/services/layers-service';
import { KOMMUN_TYPE_GROUP } from '@/constants/RouteTypeGroups';
import { API_QUERY_DATETIME_TZ_FORMAT } from '@/constants/Global';
import DatePicker from 'vue2-datepicker';
import { HistoryTransportLayer } from '../../../services/layers/history-transport-layer';
export default {
  name: 'history-detail',
  props: ['number'],
  components: { AppSidebar, DatePicker },
  data: () => ({
    openSidebar: true,
    transportId: null,
    transportRoadId: null,
    filter: {
      date: moment().format('YYYY-MM-DD'),
    },
    historyList: null,
    layers: null,
    routeGroups: null,
    detailLayer: {
      id: null,
      show: false,
      group: null,
    },
    speed: 0,
    time: 0,
    innerTime: 0,
    marker: null,
    loading: true,
    isPlayed: false,
    originalRoute: false,
    originalRouteLayer: null,
    routeViolations: null,
    showRouteViolationsLayers: false,
    urlParams: null,
    routes: [],
    transporter: null,
    transport: null,
    timeInterval: null,
    isTimeDragging: false,
    openAddRoutePopup: false,
    tripDatesSelectMode: 0,
    tripDateFrom: null,
    tripDateTo: null,
    tripRouteGroup: null,
    tripTypes: ['trip', 'trip_on_route', 'trip_on_road'],
  }),
  computed: {
    ...mapGetters({
      routeTypeGroupsMapping: 'route-type/groups',
      enabledLayers: 'map/layers',
    }),
    isActive() {
      return this.originalRoute;
    },
    checkedValue: {
      get() {
        return this.originalRoute;
      },
      set(newValue) {
        this.originalRoute = newValue;
      },
    },
    selectedRoute() {
      return this.historyList ? Object.values(this.historyList).flat().find(route => route.id === this.detailLayer.id) : null;
    },
    selectedRouteFrom() {
      if (!this.selectedRoute) {
        return 0;
      }

      const hours = this.selectedRoute.dateFrom.hours();
      const minutes = this.selectedRoute.dateFrom.minutes();
      const seconds = this.selectedRoute.dateFrom.seconds();

      return hours * 3600 + minutes * 60 + seconds - 1;
    },
    selectedRouteTo() {
      if (!this.selectedRoute) {
        return 0;
      }
      const hours = this.selectedRoute.dateTo.hours();
      const minutes = this.selectedRoute.dateTo.minutes();
      const seconds = this.selectedRoute.dateTo.seconds();

      return hours * 3600 + minutes * 60 + seconds + 1;
    },
    timeFormatted() {
      const time = this.getHoursMinutesSeconds(this.innerTime);

      return `${time.hours.toString().padStart(2, '0')}:${time.minutes.toString().padStart(2, '0')}`;
    },
  },
  methods: {
    ...mapActions({
      getTransportsLine: 'history/getTransportsLine',
      getHistory: 'history/getHistory',
    }),
    disabledBeforeAfter(date) {
      return !HistoryTransportLayer.isRangeThreeMonths(date);
    },
    startDragging() {
      this.isTimeDragging = true;
    },
    endDragging() {
      this.isTimeDragging = false;

      if (this.isPlayed) {
        this.markerMove(true);
      }
    },
    updateTime() {
      this.time = this.innerTime;
    },
    getHoursMinutesSeconds(time) {
      const seconds = time;
      let minutes = Math.floor(seconds / 60);
      const hours = Math.floor(minutes / 60);

      minutes = minutes - hours * 60;

      return {
        hours,
        minutes,
        seconds,
      };
    },
    replaceQueryDate() {
      this.$router.replace({
        query: {
          ...this.$route.query,
          date: this.filter.date,
        },
      });
      this.urlParams.set('date', this.filter.date);
    },
    changeDates() {
      if (this.filter.date) {
        this.replaceQueryDate();
        this.loadHistory();
        this.loading = true;
      }
    },
    goBack() {
      const fromHistoryTransport = this.urlParams.get('fromHistoryTransport');

      const routeTypes = this.routeTypeGroupsMapping[KOMMUN_TYPE_GROUP];
      const query = { transport: routeTypes.join(',') };
      if (this.enabledLayers.includes('historyTransport') || fromHistoryTransport) {
        query.historyTransport = 'on';
      }
      this.$router.push({
        name: 'main-map-typed',
        query,
        params: { type: routeTypes.join(',') },
      }).then(() => {
      });
    },
    renderRoutes(route) {
      if (this.tripTypes.includes(route.type)) {
        let coord = [];
        route.line.map(route => {
          coord.push(route.coordinates);
        });
        const line = L.polyline(coord, {
          weight: 5,
          opacity: 1,
          color: `#${route.color}`,
          dashArray: '3',
          fillOpacity: 0.3,
          fillColor: `#${route.color}`,
        });

        line.on('click', event => {
          this.drawRoute(route);
        });
        line.addTo(MapService.instance.map);

        this.layers.push({
          route: line,
          id: route.id,
        });
      } else {
        let myRenderer = L.canvas({ padding: 0.5 });

        route.points.forEach(el => {
          const marker = L.marker(el.point.coordinates, {
            icon: L.divIcon({
              html: '<img class="invert" style="height: 100%" src="../../assets/icons/park.png">',
              iconSize: [30, 30],
              className: '',
            }),
            renderer: myRenderer,
            id: el.id,
          }).addTo(MapService.instance.map);

          const popupContent = `Стоянка ${route.diffTime}`;
          marker.bindPopup(popupContent).on('popupopen', () => {
            this.detailLayer.id = route.id;
          });

          marker.on('popupclose', () => {
            this.detailLayer.id = null;
          });

          this.layers.push({
            route: marker,
            id: route.id,
          });
        });
      }
    },
    deleteLayers() {
      if (this.layers !== null) {
        this.layers.forEach(layer => layer.route.remove());
        this.layers = null;
      }

      if (this.detailLayer.group !== null) {
        this.detailLayer.group.remove();
        this.detailLayer.show = false;
        this.detailLayer.id = null;
        this.isPlayed = false;
        this.speed = 0;
      }
      this.layers = [];
    },
    getDiffTime(route) {
      let diffTime = Math.floor(moment.duration(moment(route.date_to).diff(moment(route.date_from))).asMinutes());
      let hoursName = '';
      if ((diffTime - 60) > 0) {
        let hours = '';
        hours = Math.trunc(diffTime / 60);
        diffTime = ('0' + (diffTime - (60 * Math.trunc(diffTime / 60)))).slice(-2);
        if (hours > 1 && hours < 5) {
          hoursName = `${hours} часа `;
        } else if (hours === 1) {
          hoursName = `${hours} час `;
        } else {
          hoursName = `${hours} часов `;
        }
      }

      return `${hoursName}${diffTime} мин`;
    },
    getDiffTimeSeconds(route) {
      let diffTime = Math.floor(moment.duration(moment(route.date_to).diff(moment(route.date_from))).asSeconds());
      let hoursAndMinsName = '';

      if (diffTime > 60) {
        hoursAndMinsName = `${this.getDiffTime(route)} `;
        diffTime = diffTime % 60;
      }

      return `${hoursAndMinsName}${diffTime} сек`;
    },
    loadHistory() {
      this.deleteLayers();
      return this.getHistory({
        transportId: this.transportId,
        dateFrom: moment(new Date(this.filter.date)).startOf('day').format(API_QUERY_DATETIME_TZ_FORMAT),
        dateTo: moment(new Date(this.filter.date)).endOf('day').format(API_QUERY_DATETIME_TZ_FORMAT),
      })
        .then((response) => {
          this.transporter = response.transport.transporter;
          this.transport = response.transport;
          this.loading = false;
          this.historyList = response.data.reduce((a, c, index) => {
            const dateFrom = moment.utc(c.date_from).local();
            const dateTo = moment.utc(c.date_to).local();
            const date = dateFrom.format('DD MMMM');
            c.diffTime = this.getDiffTime(c);
            c.dateFrom = dateFrom;
            c.dateTo = dateTo;

            c.date_from = dateFrom.format('HH:mm');
            c.date_to = dateTo.format('HH:mm');
            c.id = index + 1;

            if (c.line) {
              c.line = c.line.map(coord => {
                coord.coordinates = coord.coordinates.reverse();
                return coord;
              });
            } else {
              c.points = c.points.map(coord => {
                coord.point.coordinates = coord.point.coordinates.reverse();
                if (coord.route_group_id) {
                  coord.route_group = this.routeGroups.find(g => g.id === coord.route_group_id);
                }
                return coord;
              });
            }
            if (c.outfit && c.outfit.route_group_id) {
              c.outfit.route_group = this.routeGroups.find(g => g.id === c.outfit.route_group_id);
            }
            if (c.route_group_id) {
              c.route_group = this.routeGroups.find(g => g.id === c.route_group_id);
            }
            if (a.hasOwnProperty(date)) {
              a[date].push(c);
              return a;
            }
            a[date] = [];
            a[date].push(c);
            return a;
          }, {});

          response.data.forEach(route => {
            if (this.urlParams.get('outfitId') && route.outfit) {
              if (route.outfit.id === +this.urlParams.get('outfitId')) {
                this.showRoute(route);
              }
            } else if (this.transportRoadId) {
              if (route.transport_road_id && route.transport_road_id === this.transportRoadId) {
                this.showRoute(route);
              }
            } else {
              this.renderRoutes(route);
            }
          });
        })
        .catch();
    },
    showRoute(route) {
      if (route.id === this.detailLayer.id) {
        this.showRoutes();
        return false;
      }
      this.showRoutes();
      this.showPopup(route);
    },
    showRoutes() {
      this.deleteLayers();
      Object.keys(this.historyList).forEach(key => {
        this.historyList[key].forEach(element => {
          this.renderRoutes(element);
        });
      });
    },
    showPopup(route) {
      const layer = this.layers.find(el => el.id === route.id);
      if (layer) {
        if (layer.route instanceof L.Marker) {
          MapService.instance.map.setView(layer.route.getLatLng());
          layer.route.togglePopup();
        } else {
          MapService.instance.map.fitBounds(layer.route.getBounds());
          this.drawRoute(route);
        }
      }
    },
    drawRoute(route) {
      this.deleteLayers();
      this.marker = null;
      this.routeViolations = null;
      this.showRouteViolationsLayers = false;
      this.detailLayer.group = L.featureGroup();
      if (route.stops) {
        route.stops.forEach(stop => {
          const diff = Math.floor(moment.duration(moment(stop.date_to).diff(moment(stop.date_from))).asSeconds());
          const name = diff < 120 ? this.getDiffTimeSeconds(stop) : this.getDiffTime(stop);
          const point = stop.points[0];

          if (!point) {
            return;
          }

          let myRenderer = L.canvas({ padding: 0.5 });
          let marker = L.marker([point.point.coordinates[1], point.point.coordinates[0]], {
            icon: L.divIcon({
              html: '<img class="invert" style="height: 100%" src="/assets/icons/stop.png">',
              iconSize: [25, 25],
              className: '',
            }),
            renderer: myRenderer,
            id: point.id,
          });

          marker.bindPopup(`Время: ${moment.utc(stop.date_from).local().format('HH:mm:ss')}<br>
          Остановка: ${name}`);
          marker.setZIndexOffset(100);

          this.detailLayer.group.addLayer(marker);
        });
      }

      route.line.forEach((line, index) => {
        const colorRoute = 'green';

        if (index < route.line.length - 1) {
          let curLatlng = line.coordinates;
          let nextLatlng = route.line[index + 1].coordinates;
          const time = moment.utc(line.tracker_time).local();
          const formattedTime = moment.utc(line.tracker_time).format('YYYY-MM-DD HH:mm:ss');
          const popupContent = `
            Время прохождения: ${time.format('HH:mm:ss')} <br>
            Скорость: ${line.speed} км/ч<br>
            <button data-time="${formattedTime}" class="btn btn-primary trip trip-start">Начало рейса</button>
            <button data-time="${formattedTime}" class="btn btn-primary trip trip-end">Конец рейса</button>
          `;
          const polyline = L.polyline([curLatlng, nextLatlng], {
            weight: 5,
            color: colorRoute,
          }).bindPopup(popupContent);
          const decorator = L.polylineDecorator(polyline, {
            patterns: [
              {
                offset: 0,
                repeat: 50,
                symbol: L.Symbol.arrowHead({
                  pixelSize: 10,
                  polygon: false,
                  pathOptions: {
                    stroke: true,
                  },
                }),
              },
            ],
          }).bindPopup(popupContent);

          this.detailLayer.group.addLayer(polyline);
          this.detailLayer.group.addLayer(decorator);
        }
      });

      this.marker = L.movingMarker(
        [route.line[0].coordinates, route.line[0].coordinates],
        0,
        {
          icon: L.divIcon({
            html: '<img width="100%" src="/assets/icons/garbage-truck.svg">',
            iconSize: [36, 36],
            className: '',
          }),
        },
      );
      this.marker.setZIndexOffset(1000);
      this.detailLayer.group.addLayer(this.marker);
      this.detailLayer.group.addTo(MapService.instance.map);
      this.detailLayer.id = route.id;
      this.detailLayer.show = true;
      this.innerTime = this.selectedRouteFrom;
      this.updateTime();
    },
    markerMove(val) {
      if (!this.marker) return;

      if (val) {
        if (this.marker.isPaused()) {
          this.marker.resume();
        } else {
          this.marker.start();
        }
      } else {
        this.marker.pause();
      }

      this.isPlayed = val;
    },
    selectRoute(line) {
      line.bringToFront();
      line.setStyle({
        weight: 10,
        opacity: 1,
        color: '#00a707',
        fillColor: '#00a707',
      });
    },
  },
  mounted() {
    this.$nextTick(() => {
      MapService.instance.initMap({
        mapElement: 'map',
      });
      LayersService.instance._initTrashArea();
      LayersService.instance._trashAreaLayers.reRender();
      this.timeInterval = window.setInterval(() => {
        if (this.marker && this.selectRoute && !this.isTimeDragging && this.isPlayed) {
          const latlng = this.marker._currentLine[0];
          for (let index = 0; index < this.selectedRoute.line.length; index++) {
            const route = this.selectedRoute.line[index];

            if (route.coordinates[0] === latlng.lat && route.coordinates[1] === latlng.lng) {
              const time = moment.utc(route.tracker_time).local();
              this.innerTime = time.hours() * 3600 + time.minutes() * 60 + time.seconds();
              break;
            }
          }
        }
      }, 500);
    });

    this.$store.subscribe((mutation) => {
      if (['toggleMenu', 'openMenu', 'closeMenu'].indexOf(mutation.type) !== -1) {
        // Дождёмся, пока пройдёт анимация
        window.setTimeout(() => {
          MapService.instance.map.invalidateSize();
        }, 350);
      }
    });
  },
  created() {
    moment.locale('ru');
    this.urlParams = new URLSearchParams(location.search);
    if (this.urlParams) {
      this.transportId = +this.urlParams.get('transportId');
      this.filter.date = HistoryTransportLayer.isRangeThreeMonths(this.urlParams.get('date')) ? moment(this.urlParams.get('date')).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD');
      this.transportRoadId = +this.urlParams.get('transportRoadId');
      this.replaceQueryDate();
      this.loadHistory();
    }
  },
  beforeDestroy() {
    if (this.timeInterval !== null) {
      window.clearInterval(this.timeInterval);
    }
    LayersService.instance._trashAreaLayers.destroy();
    LayersService.instance._trashAreaLayers = null;
  },
  destroyed() {
    MapService.instance.destroy();
  },
  watch: {
    speed(val, oldVal) {
      if (this.marker.isStarted()) {
        this.marker.pause();
      }
      const oldSpeed = Math.pow(2, oldVal);
      const newSpeed = Math.pow(2, val);
      this.marker._durations = this.marker._durations.map(duration => (duration * oldSpeed) / newSpeed);
      this.marker._currentDuration = (this.marker._currentDuration * oldSpeed) / newSpeed;

      if (this.marker.isPaused() && this.isPlayed) {
        this.marker.resume();
      }
    },
    time(val, oldVal) {
      if (!this.selectRoute) {
        return;
      }

      // Запоминаем новое значение
      let newTime = Number(val);

      // Проходимся по всем координатам выбранного маршрута
      const routes = this.selectedRoute.line.reduce((obj, route, index) => {
        const date = moment.utc(route.tracker_time).local();

        // Вычисляем сколько секунд прошло с 00:00
        const hours = date.hours();
        const minutes = date.minutes();
        const seconds = date.seconds();
        const time = hours * 3600 + minutes * 60 + seconds;

        // Сравниваем с пришедшим значением
        // Если время меньше, то запоминаем индекс
        // Если время больше, то вычисляем продолжительность пути и запоминаем с координатами
        if (time <= newTime) {
          return {
            index,
            coords: obj.coords,
            durations: obj.durations,
          };
        } else {
          const diff = time - newTime;
          newTime = time - 1;

          return {
            index: obj.index,
            coords: [
              ...obj.coords,
              L.latLng(route.coordinates),
            ],
            durations: [
              ...obj.durations,
              Math.floor(diff * 1000 / Math.pow(2, this.speed)),
            ],
          };
        }
      }, {
        index: 0,
        coords: [],
        durations: [],
      });

      // Если это последняя точка в маршруте, переносим маркер в конец
      // Иначе в начало точки
      if (routes.coords.length === 1) {
        this.marker.moveTo(routes.coords[0], 0);
      } else {
        this.marker.moveTo(this.selectedRoute.line[routes.index].coordinates, 0);
      }

      // Останавливаем ТС, если не запущен интерактивный режим
      if (!this.isPlayed) {
        this.marker.pause();
      }

      // Строим дальнейший маршрут от текущей точки
      if (routes.coords.length !== 1) {
        for (let index = 0; index < routes.coords.length; index++) {
          this.marker.addLatLng(routes.coords[index], routes.durations[index]);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.cancel-button {
  position: absolute;
  z-index: 1000;
  top: 60px;
  right: 50px;
}

.btn-plus {
  height: auto;
  padding: 2px 5px;
  margin: 0 0 5px 0;
}

.history {
  padding: 20px 0;
  display: flex;
  flex-flow: column nowrap;
  height: 100%;
  overflow: hidden;

  .list {
    padding-left: 10px;
    overflow-y: auto;

    &__item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-right: 5px;

      &:hover {
        background-color: rgb(224, 224, 224);
      }

      p {
        margin-bottom: 5px;
        cursor: pointer;

        &.active {
          font-weight: 600;
        }
      }
    }
  }

  .date-list {
    padding-left: 20px;
    overflow-y: auto;
    flex: 1;

    & > li {
      margin-bottom: 20px;
      list-style: none;
    }
  }
}

.play-controls {
  position: absolute;
  bottom: 50px;
  right: 50px;
  background: #FFFFFF;
  padding: 14px;
  z-index: 500;

  .btn {
    color: #FFFFFF;
  }

  .speed-block, .time-block {
    margin: 10px 0;

    p {
      margin-bottom: 0;
    }

    .range {
      display: block;
      width: 100%;
    }
  }
}

.toggle {

  &__button {
    vertical-align: middle;
    user-select: none;
    cursor: pointer;

    & input[type="checkbox"] {
      opacity: 0;
      position: absolute;
      width: 1px;
      height: 1px;
    }

    &.active .toggle__switch {
      background: #adedcb;
      box-shadow: inset 0 0 1px #adedcb;
    }
    &.active .toggle__switch::after,
    &.active .toggle__switch::before{
      transform:translateX(40px - 18px);
    }
    &.active .toggle__switch::after {
      left: 0;
      background: #53B883;
      box-shadow: 0 0 1px #53B883;
    }
  }

  &__switch {
    display:inline-block;
    height:12px;
    border-radius:6px;
    width:40px;
    background: #BFCBD9;
    box-shadow: inset 0 0 1px #BFCBD9;
    position:relative;
    margin-left: 10px;
    transition: all .25s;

    &:before,
    &:after {
      content: '';
      position: absolute;
      display: block;
      height: 18px;
      width: 18px;
      border-radius: 50%;
      left: 0;
      top: -3px;
      transform: translateX(0);
      transition: all .25s cubic-bezier(.5, -.6, .5, 1.6);
    }

    &:after {
      background: #4D4D4D;
      box-shadow: 0 0 1px #666;
    }

    &:before {
      background: #4D4D4D;
      box-shadow: 0 0 0 3px rgba(0,0,0,0.1);
      opacity:0;
    }
  }
}

.passenger-transporation {
  div.mx-datepicker {
    width: 70%;
  }
  h5 {
    margin: 0;
    padding-top: 15px;
    font-size: 16px;

    span {
      font-size: 14px;
      font-weight: 500;
    }
  }
  .h5-date {
    padding-top: 15px;
    font-size: 16px;
    display: flex;
    justify-content: space-between;
  }
}
</style>

<style lang="scss">
.history-map .leaflet-popup-content {
  display: flex;
  flex-flow: column nowrap;

  & .detail-link {
    align-self: center;
  }

  .trip {
    display: none;
  }
}

.trip-mode {
  &.trip-mode-start {
    .trip-start {
      display: block;
    }
  }

  &.trip-mode-end {
    .trip-end {
      display: block;
    }
  }
}
</style>
